import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, FieldErrorsImpl, useWatch } from 'react-hook-form';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { FormDropdownTyped } from 'components';
import { getTimes } from 'forms/AvailabilityForm/utils';
import { SettingsAvailabilitiesFields } from 'forms/AvailabilityForm/types';

interface AvailabilityIntervalProps {
  fromTimeName: keyof SettingsAvailabilitiesFields;
  toTimeName: keyof SettingsAvailabilitiesFields;
  enabled: boolean | null;
  control: Control<SettingsAvailabilitiesFields>;
  errors: FieldErrorsImpl<SettingsAvailabilitiesFields>;
}

export function AvailabilityInterval({
  fromTimeName,
  toTimeName,
  enabled,
  control,
  errors,
}: AvailabilityIntervalProps) {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const availability = baseLanguage.settings?.availability;
  const fromTimeInputLabel = availability?.from_time_input_label;
  const toTimeInputLabel = availability?.to_time_input_label;
  const unavailableLabel = availability?.unavailable_label;

  const fromTime = useWatch({ control, name: fromTimeName });

  const entireDayOptions = useMemo(() => getTimes(), []);
  const toTimeOptions = entireDayOptions.filter(
    (time) => fromTime && time.value > fromTime,
  );

  return enabled ? (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'stretch',
        gap: 0.5,
        flex: 'auto',
        [theme.breakpoints.up('md')]: {
          alignSelf: 'unset',
          gap: 1.5,
        },
      })}
    >
      <FormDropdownTyped
        label={fromTimeInputLabel}
        name={fromTimeName}
        options={entireDayOptions}
        required={true}
        control={control}
        errors={errors}
      />
      <Typography
        variant="body2"
        component="span"
        color="text.secondary"
        sx={{ fontSize: '1rem' }}
      >
        {'-'}
      </Typography>
      <FormDropdownTyped
        label={toTimeInputLabel}
        name={toTimeName}
        options={toTimeOptions}
        required={true}
        control={control}
        errors={errors}
      />
    </Box>
  ) : (
    <Typography variant="body2" component="p" color="text.secondary">
      {unavailableLabel}
    </Typography>
  );
}

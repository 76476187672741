// external
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

// internal
import { Asterisk, FormDropdownTyped, FormTextFieldTyped } from 'components';

// Hooks
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useOrganization,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { useMobileMediaQuery } from 'hooks';
import { IRelationship } from '@guider-global/shared-types';

interface IGoalsCreateFormProps {
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  isLoadingGoals: boolean;
  discardAction: () => void;
  hideButtons?: boolean;
  relationship: IRelationship;
}

export const GoalsCreateForm: React.FC<IGoalsCreateFormProps> = ({
  handleSubmit,
  control,
  errors,
  isValid,
  isLoadingGoals,
  discardAction,
  hideButtons,
  relationship,
}) => {
  // Base Language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });

  const createGoalButtonLabel =
    baseLanguage.globals?.goals?.create_goal_button_label ?? 'Create goal';

  const isMobile = useMobileMediaQuery();

  const { getProgram } = useSanityOrganizationPrograms({});

  const program = getProgram(relationship.programSlug);
  const customGoalCategories =
    program?.registration?.goals?.custom_goal_categories;

  const selectedGoalSource =
    customGoalCategories && customGoalCategories.length > 0
      ? customGoalCategories
      : organization?.goal_categories?.categories ?? [];

  const goalCategories = selectedGoalSource.map((goalCategory) => ({
    label: goalCategory.goal_category_name,
    value: goalCategory.goal_category_slug?.current,
  }));

  return (
    <form onSubmit={handleSubmit} data-cy="forms_GoalsCreateForm">
      <Typography variant="h6" component="p">
        {`1. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.summary?.category_select_label}`}
        <Asterisk />
      </Typography>
      <FormDropdownTyped
        name="categorySlug"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.summary?.category_select_label
        }
        errors={errors}
        options={goalCategories}
        required
        data-cy="forms_GoalsCreateForm_category"
        rules={{ required: baseLanguage?.globals?.errors?.required_field }}
      />

      <Typography variant="h6" sx={{ mt: 4 }} component="p">
        {`2. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.summary?.objective_input_label}`}
      </Typography>

      <FormTextFieldTyped
        data-cy="forms_GoalsCreateForm_objective"
        name="objective"
        control={control}
        defaultValue={''}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.summary?.objective_input_label
        }
        errors={errors}
      />
      <Typography variant="h6" sx={{ mt: 4 }} component="p">
        {`3. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.outcomes?.title}`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ fontWeight: 400 }}
      >
        {
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.outcomes?.description
        }
      </Typography>
      <FormTextFieldTyped
        data-cy="forms_GoalsCreateForm_outcomes"
        name="outcomes"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.outcomes?.title
        }
        errors={errors}
        rows={2}
        defaultValue={''}
      />
      <Typography variant="h6" sx={{ mt: 4 }} component="p">
        {`4. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.benefits?.title}`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ fontWeight: 400 }}
      >
        {
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.benefits?.description
        }
      </Typography>
      <FormTextFieldTyped
        data-cy="forms_GoalsCreateForm_rationale"
        name="rationale"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.benefits?.title
        }
        defaultValue={''}
        errors={errors}
        rows={2}
      />
      {!hideButtons && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <Button
            data-cy="forms_GoalsCreateForm_discard-button"
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            onClick={discardAction}
          >
            {
              baseLanguage?.relationships?.goals?.create_and_update_goal
                ?.create_goal?.discard_button_label
            }
          </Button>
          <LoadingButton
            data-cy="forms_GoalsCreateForm_submit-button"
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid}
            sx={{
              color: isValid ? 'white' : 'inherit',
              mb: isMobile ? 2 : 0,
            }}
            fullWidth={isMobile}
            loading={isLoadingGoals}
          >
            {createGoalButtonLabel}
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};

export default GoalsCreateForm;

import {
  Avatar,
  Button,
  DropdownItemProps,
  FormTextArea,
  FormSelectInput,
  Stack,
  Text,
  TextStack,
  Divider,
  LinkCheckbox,
  FormMultiSelectInput,
  SelectareaInputValue,
  TextareaInputValue,
  FormPhoneNumberValue,
  CheckboxTextItemProps,
  MultiSelectAreaInputValue,
  FormDateField,
  DateFieldValue,
  ContentModal,
  FormPhoneNumber,
} from '@guider-global/ui';
import { SwapHoriz, Upload } from '@mui/icons-material';
import { Box, useTheme } from '@mui/system';
import {
  useCustomFields,
  useLocalization,
  useProfilePicture,
  useProfiles,
  useSettings,
  useUserPictures,
  useUsers,
} from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { getCountryList, getSubDomain } from '@guider-global/front-end-utils';
import {
  ICustomField,
  IProfile,
  IStorageResponse,
  SanityAdditionalAgreement,
  SanityProfileField,
  SanityTextBlock,
} from '@guider-global/shared-types';
import { useAuth } from '@guider-global/auth-hooks';
import { useAxios } from '@guider-global/redux-axios-hooks';
import Compressor from 'compressorjs';
import { uploadToBlob } from 'utils';
import { PortableText } from '@portabletext/react';
import { Markdown } from 'components';
import { interpolate } from 'functions';
import moment from 'moment-timezone';
import Delete from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { showAppAlert } from 'store/slices/appSlice';
import { useNavigate } from 'react-router-dom';

type OnboardingFormValues = {
  [key: string]:
    | FormPhoneNumberValue
    | TextareaInputValue
    | SelectareaInputValue
    | MultiSelectAreaInputValue
    | { fieldType: 'check'; value: string; name?: string }
    | DateFieldValue;
};

interface OnboardingFormProps {
  profile: IProfile | undefined;
}
export function OnboardingForm({ profile }: OnboardingFormProps) {
  // State and Refs
  const [checkedGuiderPolicies, setCheckedGuiderPolicies] =
    useState<boolean>(false);
  const [checkedOrganizationTerms, setCheckedOrganizationTerms] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();
  const [modalContent, setModalContent] = useState<
    SanityTextBlock[] | string
  >();
  const [formProfilePicture, setFormProfilePicture] = useState<Blob | File>();
  const [formProfilePictureObjectURL, setFormProfilePictureObjectURL] =
    useState<string | undefined>(undefined);
  const [defaultPictureHandled, setDefaultPictureHandled] =
    useState<boolean>(false);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const { picture, loading: loadingUserPicture } = useUserPictures();

  // Auth
  const { accessToken, getAccessToken } = useAuth({});
  const { users } = useUsers({
    getSilently: true,
    getSilentlyUrl: `/users`,
  });
  const user = users()[0];

  // Axios
  const { requestCallback } = useAxios({
    waitForAuthentication: true,
    accessToken,
    onExpiredAccessToken: getAccessToken,
  });

  // Theme
  const { palette } = useTheme();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });

  const allowOpenMatching =
    organization?.open_matching?.enable_open_matching ?? false;

  const { localeCode: selectedLocaleCode } = useLocalization(organizationSlug);

  const personalDetails = organization?.personal_details;

  let organizationName = organization?.basic_info.name;
  if (organizationName?.charAt(organizationName.length - 1) === 's') {
    organizationName = organizationName + "'";
  } else {
    organizationName = organizationName + "'s";
  }

  const organizationFields = organization?.profile_fields ?? [];

  // Profile picture
  const { picture: userPicture } = useProfilePicture();

  // Profiles
  const { reqProfiles, isLoadingProfiles } = useProfiles({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: '/profiles',
    queryParams: {
      integrationsEnabled: true,
    },
  });

  // Settings
  const { createSettings } = useSettings({});

  // Base Language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const openMatchingText = baseLanguage?.registration?.open_matching;
  const profileFieldText = baseLanguage?.profile_fields;

  const genders: DropdownItemProps[] = Object.entries(
    profileFieldText?.gender?.options ?? {},
  ).map(([key, label]) => {
    return {
      key,
      value: key,
      label,
    };
  });

  // Custom fields
  const { reqCustomFields } = useCustomFields({
    getSilently: false,
    forceRefresh: false,
  });

  let organizationAgreements =
    organization?.white_label?.additional_agreements !== null
      ? organization?.white_label?.additional_agreements
      : [];

  let formattedAgreements: CheckboxTextItemProps[] = [];

  organizationAgreements?.forEach(
    (agreement: SanityAdditionalAgreement, index: number) => {
      if (organizationAgreements) {
        if (
          index === organizationAgreements?.length - 1 &&
          organizationAgreements?.length > 1
        ) {
          formattedAgreements.push({ text: ' & ' });
          formattedAgreements.push({
            text: agreement.name,
            onClick: () => handleModalOpen(agreement.name, agreement.content),
          });
          return;
        } else {
          formattedAgreements.push({
            text: agreement.name,
            onClick: () => handleModalOpen(agreement.name, agreement.content),
          });
          return;
        }
      }
      return undefined;
    },
  );

  // Image handling

  async function handleImageChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFormProfilePicture(event.target.files[0]);
      setFormProfilePictureObjectURL(URL.createObjectURL(img));
    }
  }

  async function handleImageUpload(profileId: string, file: File | Blob) {
    const opts: Compressor.Options = {
      checkOrientation: true,
      maxHeight: 400,
      maxWidth: 400,
      quality: 0.9,
      mimeType: 'image/jpeg',
      resize: 'cover',
      convertSize: 600000, // 600kb
      retainExif: false,
      strict: false,
    };

    new Compressor(file, {
      ...opts,
      async success(result: File) {
        const filename = result.name;

        const axiosResult = await requestCallback<IStorageResponse>({
          method: 'POST',
          url: `/profiles/${profileId}/picture`,
          params: { filename },
        });
        const { data: axiosData } = axiosResult;

        const { data } = axiosData;
        const { sasToken, uri } = data[0];

        const uploadResult = await uploadToBlob(uri, sasToken, result);

        if (uploadResult.status === 'success') {
          await reqProfiles({
            method: 'PATCH',
            url: `/profiles/${profileId}`,
            data: {
              picture: uri,
            },
          });
        } else {
          dispatch(
            showAppAlert({
              severity: 'error',
              message: 'Couldnt upload profile image',
              timeout: 2000,
            }),
          );
        }
      },
    });
  }

  function openInput() {
    const input = document.getElementById('input');
    if (!input) return;
    input.click();
  }

  // Form handling

  const {
    control,
    formState: { isValid },
    handleSubmit,
    reset,
  } = useForm<OnboardingFormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (loading) return;
    const customFields = profile?.organizationFields ?? [];
    const fields = Object.assign(
      {},
      ...(customFields as ICustomField[]).map(
        ({ fieldType, value, fieldSlug }) => {
          if (fieldType === 'multi-select') {
            return {
              [fieldSlug]: {
                fieldType,
                value: value ?? [],
              },
            };
          } else {
            return {
              [fieldSlug]: {
                fieldType,
                value: value ?? '',
              },
            };
          }
        },
      ),
    );

    reset({
      ...fields,
      firstName: {
        fieldType: 'free-text',
        value: profile?.firstName
          ? profile?.firstName ?? ''
          : user?.givenName ?? '',
      },
      lastName: {
        fieldType: 'free-text',
        value: profile?.lastName
          ? profile?.lastName ?? ''
          : user?.familyName ?? '',
      },
      phoneNumber: {
        fieldType: 'free-text',
        value: profile?.phoneNumber
          ? profile?.phoneNumber ?? ''
          : user?.phoneNumber ?? '',
      },
      jobTitle: {
        fieldType: 'free-text',
        value: profile?.jobTitle ?? '',
      },
      linkedInUrl: {
        fieldType: 'free-text',
        value: profile?.linkedInUrl ?? '',
      },
      townOrCity: {
        fieldType: 'free-text',
        value: profile?.townOrCity ?? '',
      },
      country: {
        fieldType: 'select',
        value: profile?.country ?? '',
      },
      pronouns: {
        fieldType: 'select',
        value: profile?.pronouns ?? '',
      },
      gender: {
        fieldType: 'select',
        value: profile?.gender ?? '',
      },
      dateOfBirth: {
        fieldType: 'date',
        value: profile?.dateOfBirth ? new Date(profile?.dateOfBirth) : null,
      },
    });
  }, [profile, user, reset, loading]);

  const onSubmit: SubmitHandler<OnboardingFormValues> = async (
    data: OnboardingFormValues,
  ) => {
    setLoading(true);
    const {
      firstName,
      lastName,
      jobTitle,
      linkedInUrl,
      townOrCity,
      country,
      pronouns,
      gender,
      dateOfBirth,
      phoneNumber,
      ...customFields
    } = data;

    let dateOfBirthString = '';
    if (dateOfBirth.value) {
      dateOfBirthString =
        (dateOfBirth.value as Date).toISOString().split('T')[0] ?? '';
    }

    const profileData = {
      country: country.value as string,
      firstName: firstName.value as string,
      phoneNumber: phoneNumber.value as string,
      lastName: lastName.value as string,
      jobTitle: jobTitle.value as string,
      townOrCity: townOrCity.value as string,
      pronouns: pronouns.value as string,
      gender: gender.value as string,
      privacyPolicy: checkedGuiderPolicies ?? false,
      termsOfService: checkedGuiderPolicies ?? false,
      displayName: firstName.value + ' ' + lastName.value,
      dateOfBirth: dateOfBirthString,
      isOnboarded: false,
      linkedInUrl: linkedInUrl.value as string,
    };

    let profilesResult;

    if (profile) {
      profilesResult = await reqProfiles({
        method: 'PATCH',
        url: `/profiles/${profile.id}`,
        data: profileData,
      });
    } else {
      profilesResult = await reqProfiles({
        method: 'POST',
        url: `/profiles`,
        data: {
          ...profileData,
          roles: [],
          organizationSlug,
        },
      });
    }

    if (profilesResult.status === 'success' && profilesResult.data) {
      const profileId = profilesResult?.data?.at(0)?.id ?? '';

      if (formProfilePicture) {
        await handleImageUpload(profileId ?? '', formProfilePicture);
      }

      const customFieldsData = Object.values(customFields)
        .filter((field) => field.name)
        .map(({ fieldType, value, name }) => {
          return {
            fieldSlug: name,
            organizationSlug,
            fieldType,
            value,
            profileId,
          };
        });

      organizationAgreements?.forEach((agreement) => {
        const regex = /[^A-Za-z0-9]/g;
        const validatedAgreementName =
          agreement.agreement_name.current.replaceAll(regex, '');

        customFieldsData.push({
          fieldSlug: `${validatedAgreementName}-${agreement._key}`,
          organizationSlug,
          fieldType: 'check',
          value: 'true',
          profileId,
        });
      });

      const timezone = moment.tz.guess(true);

      const [settingsResult, customFieldsResult] = await Promise.all([
        createSettings({
          profile: profileId,
          localeCode: selectedLocaleCode,
          timezone,
        }),
        reqCustomFields({
          method: 'POST',
          url: `/customfields`,
          data: [...customFieldsData] as ICustomField[],
        }),
      ]);

      if (settingsResult.status === 'error') {
        dispatch(
          showAppAlert({
            severity: 'error',
            message: settingsResult.message,
            timeout: 2000,
          }),
        );
      }

      if (customFieldsResult.status === 'error') {
        dispatch(
          showAppAlert({
            severity: 'error',
            message: customFieldsResult.message,
            timeout: 2000,
          }),
        );
      }
      navigate('./bio');
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: 'Error creating profile',
          timeout: 2000,
        }),
      );
    }

    setLoading(false);
  };

  // Countries

  const countryList: DropdownItemProps[] = useMemo(
    () =>
      getCountryList({ countryCode: 'GB', languageCode: 'en' }).map(
        ({ key, countryName }) => ({
          key,
          label: countryName,
          value: key,
        }),
      ),
    [],
  );

  // Modal handling

  function handleModalOpen(
    title: string | undefined,
    content: SanityTextBlock[] | string | undefined,
  ) {
    setModalOpen(true);
    setModalContent(content);
    setModalTitle(title);
  }

  function closeModal() {
    setModalOpen(false);
    setModalContent(undefined);
    setModalTitle('');
  }

  useEffect(() => {
    if (picture && !defaultPictureHandled) {
      setFormProfilePicture(picture);
      setFormProfilePictureObjectURL(URL.createObjectURL(picture));
      setDefaultPictureHandled(true);
    }
  }, [defaultPictureHandled, picture]);

  useEffect(() => {
    if (
      allowOpenMatching &&
      profile &&
      profile.termsOfService &&
      profile.privacyPolicy &&
      !loading
    ) {
      navigate('./bio');
    }
  }, [profile, allowOpenMatching, navigate, loading]);

  if (!baseLanguage) return <></>;

  return (
    <>
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
        pt={{ xs: 4, md: 2 }}
      >
        <TextStack
          size={'xs'}
          heading={
            openMatchingText?.profile?.preheader ?? 'First things first.'
          }
          subtitles={[
            {
              text: openMatchingText?.profile?.title ?? 'Create Your Profile',
              variant: 'h2',
            },
          ]}
        />
        <Stack direction={'column'} gap={1} spacing={0}>
          <Text
            text={profileFieldText?.picture?.picture_input_label ?? 'Photo'}
            variant="h6"
          />
          <Stack direction={'row'}>
            <Avatar
              size="large"
              sx={{ borderRadius: '4px' }}
              src={formProfilePictureObjectURL ?? userPicture}
              dataCy={'profile-avatar'}
            />
            <Stack
              direction={'column'}
              gap={1}
              spacing={0}
              justifyContent={'center'}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={inputFile}
                onClick={(
                  event: React.MouseEvent<HTMLInputElement, MouseEvent>,
                ) => {
                  const element = event.target as HTMLInputElement;
                  element.value = '';
                }}
                id="input"
                hidden
              />
              {!formProfilePictureObjectURL && (
                <Box>
                  <Button
                    color="gray"
                    variant="outlined"
                    label={
                      openMatchingText?.profile?.upload_photo ??
                      'Upload picture'
                    }
                    startIcon={<Upload />}
                    onClick={openInput}
                    sx={{
                      px: 3,
                      color: palette.text.primary,
                      '&&:hover': { color: palette.info.main },
                      animation: 'fadeIn 700ms',
                      '@keyframes fadeIn': {
                        ' 0%': { opacity: 0 },
                        '50%': { opacity: 0.5 },
                        '100%': { opacity: 1 },
                      },
                    }}
                    data-cy={'upload-profile-picture'}
                  />
                </Box>
              )}
              {formProfilePictureObjectURL && (
                <Box>
                  <Button
                    color="gray"
                    variant="outlined"
                    label={
                      openMatchingText?.profile?.change_photo ??
                      'Change picture'
                    }
                    startIcon={<SwapHoriz />}
                    onClick={openInput}
                    sx={{
                      px: 3,
                      color: palette.text.primary,
                      '&&:hover': { color: palette.info.main },
                      animation: 'fadeIn 700ms',
                      '@keyframes fadeIn': {
                        ' 0%': { opacity: 0 },
                        '50%': { opacity: 0.5 },
                        '100%': { opacity: 1 },
                      },
                    }}
                  />
                </Box>
              )}
              {formProfilePictureObjectURL && (
                <Box>
                  <Button
                    variant="text"
                    label={
                      baseLanguage?.profile_fields?.picture?.remove_picture ??
                      'Remove picture'
                    }
                    startIcon={<Delete />}
                    sx={{
                      color: palette.error.main,
                      '&&:hover': { color: palette.error.dark },
                      animation: 'fadeIn 700ms',
                      '@keyframes fadeIn': {
                        ' 0%': { opacity: 0 },
                        '50%': { opacity: 0.5 },
                        '100%': { opacity: 1 },
                      },
                      justifyContent: 'flex-start',
                      p: 0,
                      m: 0,
                      height: 'min-content',
                    }}
                    onClick={() => {
                      setFormProfilePicture(undefined);
                      setFormProfilePictureObjectURL(undefined);
                    }}
                    data-cy={'delete-profile-picture'}
                  />
                </Box>
              )}

              <Text
                text={
                  openMatchingText?.profile?.photo_subtext ??
                  'Upload a profile photo to personalise your account and help others recognise you!'
                }
                variant="subtitle2"
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
          direction={'column'}
          gap={1}
        >
          <FormTextArea
            control={control}
            rows={1}
            label={
              (profileFieldText?.first_name_input_label ?? 'First Name') + '*'
            }
            labelSize="xs"
            placeholder={
              (profileFieldText?.first_name_input_label ?? 'First Name') + '*'
            }
            name="firstName"
            errorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            dataCy={'profile-first-name'}
          />
          <FormTextArea
            control={control}
            rows={1}
            label={
              (profileFieldText?.last_name_input_label ?? 'Last Name') + '*'
            }
            labelSize="xs"
            placeholder={
              (profileFieldText?.last_name_input_label ?? 'Last Name') + '*'
            }
            name="lastName"
            errorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            dataCy={'profile-last-name'}
          />
          <FormDateField
            maxDate={new Date()}
            minDate={new Date('1900/01/01')}
            name="dateOfBirth"
            placeholder={
              personalDetails?.date_of_birth?.custom_label ??
              profileFieldText?.date_of_birth_input_label
            }
            label={
              personalDetails?.date_of_birth?.custom_label ??
              profileFieldText?.date_of_birth_input_label +
                (personalDetails?.date_of_birth?.required ? '*' : '')
            }
            labelSize="xs"
            control={control}
            hidden={!personalDetails?.date_of_birth?.enabled}
            required={personalDetails?.date_of_birth?.required}
            validationErrorMessage={interpolate(
              baseLanguage?.globals?.errors?.validation_error_blank,
              {
                fieldName:
                  personalDetails?.date_of_birth?.custom_label ??
                  profileFieldText?.date_of_birth_input_label,
              },
            )}
            requiredErrorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            dataCy={'profile-dob'}
          />
          <FormSelectInput
            hidden={!personalDetails?.gender?.enabled}
            required={personalDetails?.gender?.required}
            control={control}
            label={
              personalDetails?.gender?.custom_label ??
              profileFieldText?.gender?.gender_input_label +
                (personalDetails?.gender?.required ? '*' : '')
            }
            name="gender"
            options={genders}
            placeholder={
              personalDetails?.gender?.custom_label ??
              profileFieldText?.gender?.gender_input_label
            }
            labelSize="xs"
            errorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            dataCy={'profile-gender'}
          />
          <FormTextArea
            hidden={!personalDetails?.pronouns?.enabled}
            required={personalDetails?.pronouns?.required}
            control={control}
            rows={1}
            label={
              personalDetails?.pronouns?.custom_label ??
              profileFieldText?.pronouns_input_label +
                (personalDetails?.pronouns?.required ? '*' : '')
            }
            labelSize="xs"
            placeholder={
              personalDetails?.pronouns?.custom_label ??
              profileFieldText?.pronouns_input_label
            }
            name="pronouns"
            errorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            dataCy={'profile-pronouns'}
          />

          <FormTextArea
            hidden={!personalDetails?.job_title?.enabled}
            required={personalDetails?.job_title?.required}
            control={control}
            rows={1}
            label={
              personalDetails?.job_title?.custom_label ??
              profileFieldText?.job_title_input_label +
                (personalDetails?.job_title?.required ? '*' : '')
            }
            labelSize="xs"
            placeholder={
              personalDetails?.job_title?.custom_label ??
              profileFieldText?.job_title_input_label
            }
            name="jobTitle"
            errorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            dataCy={'profile-job-title'}
          />
          <FormTextArea
            hidden={!personalDetails?.linkedin_url.enabled}
            required={personalDetails?.linkedin_url.required}
            control={control}
            rows={1}
            label={
              personalDetails?.linkedin_url.custom_label ??
              profileFieldText?.linkedin_url?.input_label +
                (personalDetails?.linkedin_url.required ? '*' : '')
            }
            labelSize="xs"
            placeholder={
              personalDetails?.linkedin_url.custom_label ??
              profileFieldText?.linkedin_url?.input_label
            }
            name="linkedInUrl"
            errorMessage={
              baseLanguage?.globals?.errors?.required_field ??
              'This field cannot be left blank.'
            }
            type="url"
            validationErrorMessage={interpolate(
              baseLanguage?.globals?.errors?.validation_error_blank,
              {
                fieldName:
                  personalDetails?.linkedin_url.custom_label ??
                  profileFieldText?.linkedin_url?.input_label,
              },
            )}
            dataCy={'profile-linkedin'}
          />
          <FormPhoneNumber
            dataCy={'profile-phoneNumber'}
            control={control}
            name="phoneNumber"
            label={
              profileFieldText?.phone_number_input_label +
              (personalDetails?.phone_number?.required ? '*' : '')
            }
            placeholder={profileFieldText?.phone_number_input_label}
            validationErrorMessage={interpolate(
              baseLanguage?.globals?.errors?.validation_error_blank,
              {
                fieldName: profileFieldText?.phone_number_input_label ?? '',
              },
            )}
            hidden={!personalDetails?.phone_number?.enabled}
            required={personalDetails?.phone_number?.required}
            initialCountry="gb"
          />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={0}
            gap={3}
            display={
              !personalDetails?.town_or_city?.enabled &&
              !personalDetails?.country?.enabled
                ? 'none'
                : ''
            }
          >
            <Box
              display={personalDetails?.town_or_city?.enabled ? '' : 'none'}
              width={'100%'}
            >
              <FormTextArea
                hidden={!personalDetails?.town_or_city?.enabled}
                required={personalDetails?.town_or_city?.required}
                control={control}
                rows={1}
                label={
                  personalDetails?.town_or_city?.custom_label ??
                  profileFieldText?.town_or_city_input_label +
                    (personalDetails?.town_or_city?.required ? '*' : '')
                }
                labelSize="xs"
                placeholder={
                  personalDetails?.town_or_city?.custom_label ??
                  profileFieldText?.town_or_city_input_label
                }
                name="townOrCity"
                errorMessage={
                  baseLanguage?.globals?.errors?.required_field ??
                  'This field cannot be left blank.'
                }
                dataCy={'profile-town-city'}
              />
            </Box>
            <Box
              display={personalDetails?.country?.enabled ? '' : 'none'}
              width={'100%'}
            >
              <FormSelectInput
                hidden={!personalDetails?.country?.enabled}
                required={personalDetails?.country?.required}
                control={control}
                label={
                  personalDetails?.country?.custom_label ??
                  profileFieldText?.country?.country_input_label +
                    (personalDetails?.country?.required ? '*' : '')
                }
                name="country"
                options={countryList}
                placeholder={
                  personalDetails?.country?.custom_label ??
                  profileFieldText?.country?.country_input_label
                }
                labelSize="xs"
                errorMessage={
                  baseLanguage?.globals?.errors?.required_field ??
                  'This field cannot be left blank.'
                }
                dataCy={'profile-country'}
              />
            </Box>
          </Stack>
          <Stack direction={'column'} data-cy={'organization-fields-stack'}>
            {organizationFields.map((field: SanityProfileField) => {
              const fieldOptions: DropdownItemProps[] | undefined =
                field.options?.map((option: any) => {
                  return {
                    key: option.id.current,
                    value: option.id.current,
                    label: option.label,
                  };
                });

              if (field.hidden) return undefined;

              if (field.type === 'free-text') {
                const isSingleLine =
                  field.free_text_config?.text_area_size === 'singleline';
                return (
                  <FormTextArea
                    tooltipText={field.text.assistive_text}
                    required={field.required}
                    key={field.id.current}
                    control={control}
                    rows={isSingleLine ? 1 : 7}
                    label={field.text.label + (field.required ? '*' : '')}
                    labelSize="xs"
                    placeholder={field.text.label}
                    name={field.id.current}
                    errorMessage={
                      baseLanguage?.globals?.errors?.required_field ??
                      'This field cannot be left blank.'
                    }
                    dataCy={
                      isSingleLine
                        ? 'organization-text-field'
                        : 'organization-text-area'
                    }
                  />
                );
              } else if (field.type === 'select') {
                return fieldOptions ? (
                  <FormSelectInput
                    tooltipText={field.text.assistive_text}
                    required={field.required}
                    key={field.id.current}
                    control={control}
                    label={field.text.label + (field.required ? '*' : '')}
                    name={field.id.current}
                    options={fieldOptions}
                    placeholder={field.text.label}
                    labelSize="xs"
                    errorMessage={
                      baseLanguage?.globals?.errors?.required_field ??
                      'This field cannot be left blank.'
                    }
                    dataCy={'organization-select-input'}
                  />
                ) : undefined;
              }
              return fieldOptions ? (
                <FormMultiSelectInput
                  tooltipText={field.text.assistive_text}
                  required={field.required}
                  key={field.id.current}
                  control={control}
                  label={field.text.label + (field.required ? '*' : '')}
                  name={field.id.current}
                  options={fieldOptions}
                  placeholder={field.text.label}
                  labelSize="xs"
                  errorMessage={
                    baseLanguage?.globals?.errors?.required_field ??
                    'This field cannot be left blank.'
                  }
                  dataCy={'organization-multi-select'}
                />
              ) : undefined;
            })}
          </Stack>

          <Divider />

          <LinkCheckbox
            dataCy={'guider-policies-checkbox'}
            isChecked={checkedGuiderPolicies}
            onChange={() => setCheckedGuiderPolicies(!checkedGuiderPolicies)}
            text={[
              {
                text:
                  baseLanguage?.legal_notices?.i_agree_to_legals_label +
                  " Guider's ",
              },
              {
                onClick: () =>
                  handleModalOpen(
                    baseLanguage?.legal_notices?.privacy_policy
                      ?.privacy_policy_label,
                    baseLanguage?.legal_notices?.privacy_policy
                      ?.privacy_policy_content,
                  ),
                text:
                  baseLanguage?.legal_notices?.privacy_policy
                    ?.privacy_policy_label + ',',
              },
              {
                onClick: () =>
                  handleModalOpen(
                    baseLanguage?.legal_notices?.cookie_policy
                      ?.cookie_policy_label,
                    baseLanguage?.legal_notices?.cookie_policy
                      ?.cookie_policy_content,
                  ),
                text: baseLanguage?.legal_notices?.cookie_policy
                  ?.cookie_policy_label,
              },
              { text: '&' },
              {
                onClick: () =>
                  handleModalOpen(
                    baseLanguage?.legal_notices?.terms_of_service
                      ?.terms_of_service_label,
                    baseLanguage?.legal_notices?.terms_of_service
                      ?.terms_of_service_content,
                  ),
                text: baseLanguage?.legal_notices?.terms_of_service
                  ?.terms_of_service_label,
              },
            ]}
          />

          {organizationAgreements?.length !== 0 && (
            <LinkCheckbox
              dataCy={'organization-policies-checkbox'}
              isChecked={checkedOrganizationTerms}
              onChange={() =>
                setCheckedOrganizationTerms(!checkedOrganizationTerms)
              }
              text={[
                {
                  text:
                    baseLanguage?.legal_notices?.i_agree_to_legals_label +
                    ' ' +
                    organizationName,
                },
                ...formattedAgreements,
              ]}
            />
          )}
          <Button
            data-cy={'registration-submit-button'}
            loading={loading || isLoadingProfiles() || loadingUserPicture}
            variant="contained"
            label={baseLanguage?.globals?.common?.continue_button_label}
            color="info"
            type="submit"
            disabled={
              !isValid ||
              !checkedGuiderPolicies ||
              (!checkedOrganizationTerms &&
                organizationAgreements?.length !== 0)
            }
          />
        </Stack>
      </Stack>

      <ContentModal
        open={modalOpen}
        onClose={closeModal}
        heading={modalTitle}
        primaryButtonProps={{
          label: baseLanguage?.globals?.common?.close_button_label,
          onClick: closeModal,
          color: 'info',
        }}
        paperSx={{
          width: { sm: '100%', md: '600px' },
          mx: { sm: 0, md: '' },
        }}
      >
        <Box px={2}>
          {typeof modalContent === 'string' ? (
            <Markdown>{modalContent}</Markdown>
          ) : (
            <Box component={PortableText} value={modalContent ?? []} />
          )}
        </Box>
      </ContentModal>
    </>
  );
}

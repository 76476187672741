// external
import WatchLater from '@mui/icons-material/WatchLater';
import { Box, Divider, InputAdornment } from '@mui/material';
import React from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormWatch,
} from 'react-hook-form';

// components
import {
  FormDatePicker,
  FormDropdown,
  FormTextField,
  ISessionLogInputs,
} from 'components';

// hooks
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { add, format, toDate } from 'date-fns';
import { useMobileMediaQuery } from 'hooks';

//types

interface ISessionsLogFormProps {
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  isLoadingSessions: boolean;
  discardAction: () => void;
  getValues: UseFormGetValues<ISessionLogInputs>;
  watch: UseFormWatch<ISessionLogInputs>;
  defaultTitle?: string;
  hideButtons?: boolean;
}

export const SessionsLogForm: React.FC<ISessionsLogFormProps> = ({
  handleSubmit,
  watch,
  control,
  errors,
  isValid,
  isLoadingSessions,
  discardAction,
  defaultTitle,
}) => {
  //style
  const isMobile = useMobileMediaQuery();

  //base language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // Availabilities

  const startTime = '00:00';
  const endTime = '00:00';

  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');
  const now = new Date();
  const currentDate = watch('date') || now;
  const currentStartTime = watch('startTime') || '';

  const startHoursWithOffset = startHours !== '00' ? parseInt(startHours) : 0;
  const endHoursWithOffset = endHours !== '00' ? parseInt(endHours) : 24;

  const startDay = new Date(currentDate);
  startDay.setHours(startHoursWithOffset, parseInt(startMinutes) || 0, 0);

  const endDay = new Date(currentDate);
  endDay.setHours(endHoursWithOffset, parseInt(endMinutes) || 0, 0);

  const startSlots = [];
  const endSlots = [];

  for (let i = startDay; i <= endDay; i = add(i, { minutes: 15 })) {
    startSlots.push(i);
  }

  if (currentStartTime) {
    const [hours, minutes] = currentStartTime.split(':');
    const startDatetime = toDate(startDay);
    startDatetime.setHours(parseInt(hours), parseInt(minutes));

    for (let i = startDatetime; i < endDay; i = add(i, { minutes: 15 })) {
      endSlots.push(add(i, { minutes: 15 }));
    }
  }

  startSlots.pop();

  const startTimeOptionsFiltered = startSlots;

  const startTimeOptions = startTimeOptionsFiltered.map((slot) => {
    const formattedSlot = format(slot, 'HH:mm');

    return {
      label: formattedSlot,
      value: formattedSlot,
      disabled: slot > now,
      ...{},
    };
  });

  const endTimeOptions = endSlots.map((slot) => {
    const formattedSlot = format(slot, 'HH:mm');

    return {
      label: formattedSlot,
      value: formattedSlot,
      disabled: slot > now,
      ...{},
    };
  });

  return (
    <form onSubmit={handleSubmit}>
      <FormTextField
        name="title"
        control={control}
        label={
          baseLanguage.relationships?.sessions?.common
            ?.session_title_input_label
        }
        errors={errors}
        required
        defaultValue={defaultTitle}
      />
      <FormDatePicker
        name="date"
        control={control}
        label={baseLanguage.relationships?.sessions?.common?.date_input_label}
        errors={errors}
        required
        defaultValue={now}
        maxDate={now}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <FormDropdown
          name="startTime"
          data-cy="forms_session_FormTimeField_startTime"
          control={control}
          label={
            baseLanguage.relationships?.sessions?.common?.start_time_input_label
          }
          errors={errors}
          required
          options={startTimeOptions}
          noOptionsLabel={
            baseLanguage.relationships?.sessions?.common
              ?.no_slots_available_label
          }
          startAdornment={
            <InputAdornment position="start">
              <WatchLater />
            </InputAdornment>
          }
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{ mx: 1.25, border: 'none' }}
        />
        <FormDropdown
          name="endTime"
          control={control}
          label={
            baseLanguage.relationships?.sessions?.common?.end_time_input_label
          }
          errors={errors}
          required
          options={endTimeOptions}
          startAdornment={
            <InputAdornment position="start">
              <WatchLater />
            </InputAdornment>
          }
          disabled={!watch('startTime')}
          data-cy="forms_session_FormTimeField_endTime"
        />
      </Box>
    </form>
  );
};

// external
import { Box, SxProps, Theme } from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import { Control, FieldErrors, UseFormReset } from 'react-hook-form';

// internal

// components
import { INoteInputs, emptyInitialValue } from 'components';
import { RelationshipNotesModalContext } from 'modals';

// hooks
import { INote } from '@guider-global/shared-types';
import { useMobileMediaQuery, useUsers } from 'hooks';
import { useLocation } from 'react-router-dom';

import {
  NoteStatusUnsaved,
  NoteCollaborate,
  NoteDetails,
  NoteStatusSaved,
  NoteTitle,
  NoteContent,
} from './components';

//types
export enum NoteFormField {
  Title = 'title',
  Content = 'content',
  IsPublic = 'isPublic',
}

export interface INotesCreateFormProps {
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<INoteInputs>;
  reset: UseFormReset<INoteInputs>;
  lastUpdatedBy?: string;
  createdBy?: string;
  note?: Partial<INote>;
  sx?: SxProps<Theme>;
}

export const NotesCreateForm: React.FC<INotesCreateFormProps> = ({
  handleSubmit,
  control,
  errors,
  reset,
  lastUpdatedBy,
  createdBy,
  note,
  sx = {},
}) => {
  const isMobile = useMobileMediaQuery();

  const { users } = useUsers({ getSilently: false });
  const user = users().at(0);

  const { pathname } = useLocation();

  const isNoteCreation = useMemo(() => {
    return pathname.includes('/create');
  }, [pathname]);

  const { setIsDirtyNote } = useContext(RelationshipNotesModalContext);

  const isPublic = useMemo(
    () => (typeof note?.isPrivate === 'undefined' ? false : !note?.isPrivate),
    [note?.isPrivate],
  );

  useEffect(() => {
    reset({
      title: note?.title ?? '',
      content: note?.content ?? emptyInitialValue,
      isPublic,
    });
  }, [note, reset, isPublic]);

  useEffect(() => {
    return () => setIsDirtyNote(false);
  }, [setIsDirtyNote]);

  return (
    <Box
      id={note?.id}
      key={note?.id}
      component="form"
      onSubmit={handleSubmit}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      data-cy="relationship-notes-modal-note-form"
    >
      {!isMobile && isNoteCreation && <NoteStatusUnsaved />}
      {!isMobile && !isNoteCreation && (
        <NoteStatusSaved lastUpdatedBy={lastUpdatedBy} createdBy={createdBy} />
      )}
      <NoteTitle control={control} errors={errors} initialValue={note?.title} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          mt: 2.5,
        }}
      >
        <NoteContent control={control} initialValue={note?.content} />
      </Box>

      {isMobile ? (
        <NoteDetails>
          {isNoteCreation && <NoteStatusUnsaved />}
          {!isNoteCreation && (
            <NoteStatusSaved
              lastUpdatedBy={lastUpdatedBy}
              createdBy={createdBy}
            />
          )}
          <NoteCollaborate
            control={control}
            errors={errors}
            defaultValue={isPublic}
            disabled={isNoteCreation ? false : note?.ownerId !== user?.id}
          />
        </NoteDetails>
      ) : (
        <NoteCollaborate
          control={control}
          errors={errors}
          defaultValue={isPublic}
          disabled={isNoteCreation ? false : note?.ownerId !== user?.id}
        />
      )}
    </Box>
  );
};

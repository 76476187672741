// external
import LocationOn from '@mui/icons-material/LocationOn';
import VideoCameraFront from '@mui/icons-material/VideoCameraFront';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { add, format } from 'date-fns';
import { toDate, utcToZonedTime } from 'date-fns-tz';
import React, { useEffect } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';

// components
import {
  FormDatePicker,
  FormDropdown,
  FormTextField,
  ISessionInputs,
} from 'components';

// hooks
import {
  useLocalization,
  useMixpanelEvents,
  useMobileMediaQuery,
  useUserIntegrations,
} from 'hooks';

// types
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { ISession } from '@guider-global/shared-types';
import { TimeAdornmentStart } from '../components';
import { useDateTimeSlots } from '../hooks';

interface ISessionsEditFormProps {
  session: ISession;
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  isDirty: boolean;
  isLoadingSessions: boolean;
  discardAction: () => void;
  setValue: UseFormSetValue<ISessionInputs>;
  hideButtons?: boolean;
  timezone: string;
}

export const SessionsEditForm: React.FC<ISessionsEditFormProps> = ({
  session,
  handleSubmit,
  control,
  errors,
  isValid,
  isLoadingSessions,
  discardAction,
  setValue,
  hideButtons,
  timezone,
}) => {
  const [date, startTime, location, videoConferencing] = useWatch({
    control,
    name: ['date', 'startTime', 'location', 'videoConferencing'],
  });

  //style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  //utils

  const { relationshipId } = useParams();
  const navigate = useNavigate();
  const { trackScreenTime } = useMixpanelEvents();

  //base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const sessionsBaseLanguage = baseLanguage?.relationships?.sessions;

  //userIntegrations
  const { userIntegrations } = useUserIntegrations({
    waitForAuthentication: true,
  });

  const { organization } = useOrganization({
    organizationSlug,
  });
  const whiteLabel = organization?.white_label;
  const enabledIntegrations = whiteLabel?.integrations;
  const videoIntegrations = enabledIntegrations?.video_conferencing ?? {};
  const calendarIntegrations = enabledIntegrations?.calendar;

  const hasCalendarIntegrations =
    Object.values(calendarIntegrations ?? {}).filter((value) => value === true)
      .length !== 0;

  const hasVideoIntegrations =
    Object.entries(videoIntegrations).filter(
      ([key, value]) => key !== 'guider_video' && value === true,
    ).length !== 0;

  const hasMSTeamsIntegrated = userIntegrations?.find(
    (integration) => integration.integrationName === 'active-directory-teams',
  );

  //date time slots
  const sessionStartTime = format(toDate(session.start), 'HH:mm');
  const now = utcToZonedTime(new Date(), timezone);
  const currentDate = date ?? now;
  const currentStartTime = startTime ?? sessionStartTime ?? '';
  const {
    isLoadingSlots,
    startTimeOptions,
    endTimeOptions,
    hasUnavailableSlots,
  } = useDateTimeSlots({
    now,
    currentDate,
    currentStartTime,
  });

  const { name } = session;

  //handlers
  const handleIntegrationsModalOpen = () => {
    navigate(`/relationships/${relationshipId}/sessions/integrate`);
  };

  useEffect(() => {
    if (startTime) {
      setValue('endTime', '');
    }
  }, [startTime, setValue]);

  return (
    <form onSubmit={handleSubmit}>
      <FormTextField
        name="title"
        control={control}
        label={sessionsBaseLanguage?.common?.session_title_input_label}
        errors={errors}
        required
        defaultValue={name}
      />
      <FormDatePicker
        name="date"
        control={control}
        label={sessionsBaseLanguage?.common?.date_input_label}
        errors={errors}
        required
        minDate={session.pastSession ? undefined : now}
        defaultValue={toDate(session.start)}
        maxDate={hasUnavailableSlots ? add(now, { days: 62 }) : undefined}
      />
      {hasCalendarIntegrations && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.75rem',
            textTransform: 'none',
            ml: 0.25,
            mt: 0.5,
          }}
        >
          <Link
            onClick={() => {
              trackScreenTime(
                'Relationship - Session form - Manage calendar intergrations',
              );
              handleIntegrationsModalOpen();
            }}
            sx={{
              textDecoration: 'none',
              ':hover': { cursor: 'pointer' },
              color: theme.palette.info.main,
            }}
          >
            {
              sessionsBaseLanguage?.common
                ?.manage_calendar_integrations_button_label
            }
          </Link>
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <FormDropdown
          name="startTime"
          control={control}
          label={sessionsBaseLanguage?.common?.start_time_input_label}
          errors={errors}
          required
          options={startTimeOptions}
          noOptionsLabel={
            sessionsBaseLanguage?.common?.no_slots_available_label
          }
          startAdornment={<TimeAdornmentStart isLoading={isLoadingSlots} />}
          disabled={isLoadingSlots}
          defaultValue={format(toDate(session.start), 'HH:mm')}
          data-cy="relationship-edit-session-modal-start-time"
        />
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mx: 1.25, border: 'none' }}
          />
        )}
        <FormDropdown
          name="endTime"
          control={control}
          label={sessionsBaseLanguage?.common?.end_time_input_label}
          errors={errors}
          required
          options={endTimeOptions}
          startAdornment={<TimeAdornmentStart isLoading={isLoadingSlots} />}
          defaultValue={format(toDate(session.end), 'HH:mm')}
          disabled={isLoadingSlots || !startTime}
          data-cy="relationship-edit-session-modal-end-time"
        />
      </Box>
      <FormDropdown
        name="videoConferencing"
        control={control}
        label={sessionsBaseLanguage?.common?.video_conferencing_select_label}
        errors={errors}
        options={[
          ...(hasMSTeamsIntegrated
            ? [
                {
                  label: sessionsBaseLanguage?.common?.ms_teams_select_option,
                  value: 'ms-teams',
                },
              ]
            : []),
          {
            label: sessionsBaseLanguage?.common?.guider_video_select_option,
            value: 'guider',
          },
          {
            label: sessionsBaseLanguage?.common?.no_video_select_option,
            value: '',
          },
        ]}
        required={location === ''}
        defaultValue={session.videoConferencing}
        startAdornment={
          <InputAdornment position="start">
            <VideoCameraFront />
          </InputAdornment>
        }
      />
      {hasVideoIntegrations && (
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '0.75rem',
            textTransform: 'none',
            ml: 0.25,
            mt: 0.5,
          }}
        >
          <Link
            onClick={() => {
              trackScreenTime(
                'Relationship - Session form - Manage conferencing intergrations',
              );
              handleIntegrationsModalOpen();
            }}
            sx={{
              textDecoration: 'none',
              ':hover': { cursor: 'pointer' },
              color: theme.palette.info.main,
            }}
          >
            {
              sessionsBaseLanguage?.common
                ?.manage_conferencing_integrations_button_label
            }
          </Link>
        </Typography>
      )}
      <FormTextField
        name="location"
        control={control}
        label={sessionsBaseLanguage?.common?.location_input_label}
        errors={errors}
        defaultValue={session.location}
        required={
          !videoConferencing && {
            value: true,
            message: `You must provide either ${sessionsBaseLanguage?.common?.location_input_label} or ${sessionsBaseLanguage?.common?.video_conferencing_select_label} for the session`,
          }
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOn />
            </InputAdornment>
          ),
        }}
      />
      {!hideButtons && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <Button
            data-cy="relationship-edit-session-modal-discard-button"
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            onClick={discardAction}
            disabled={isLoadingSessions}
          >
            {baseLanguage?.globals?.common?.cancel_button_label ?? 'Cancel'}
          </Button>
          <LoadingButton
            data-cy="relationship-edit-session-modal-submit-button"
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid}
            sx={{
              color: isValid ? 'white' : 'inherit',
              mb: isMobile ? 2 : 0,
            }}
            fullWidth={isMobile}
            loading={isLoadingSessions}
          >
            {
              sessionsBaseLanguage?.new_session_modal
                ?.send_calendar_invite_button_label
            }
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};

export default SessionsEditForm;

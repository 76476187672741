import CalendarToday from '@mui/icons-material/CalendarToday';
import Edit from '@mui/icons-material/Edit';
import { Box, Typography, useTheme } from '@mui/material';
import { INotesCreateFormProps } from '../..';

export function NoteStatusSaved({
  lastUpdatedBy,
  createdBy,
}: Pick<INotesCreateFormProps, 'lastUpdatedBy' | 'createdBy'>) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        mt: 2,
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      }}
      data-cy="relationship-notes-modal-note-form-status-saved"
    >
      <Typography
        color="text.secondary"
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
          [theme.breakpoints.down('md')]: {
            mb: 2,
          },
          [theme.breakpoints.up('md')]: {
            mr: 2,
          },
        }}
      >
        <Edit
          fontSize="small"
          sx={{ mr: 1, color: theme.palette.text.disabled }}
        />
        {lastUpdatedBy}
      </Typography>
      <Typography
        color="text.secondary"
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
        }}
      >
        <CalendarToday
          fontSize="small"
          sx={{ mr: 1, color: theme.palette.text.disabled }}
        />
        {createdBy}
      </Typography>
    </Box>
  );
}

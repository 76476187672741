// external
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';

// components
import { Asterisk, FormDropdownTyped, FormTextFieldTyped } from 'components';

// Hooks
import {
  useOrganization,
  useSanityBaseLanguage,
  useSanityOrganizationPrograms,
} from '@guider-global/sanity-hooks';

// types
import { getSubDomain } from '@guider-global/front-end-utils';
import { IGoal, IRelationship } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

interface IGoalsEditFormProps {
  goal: IGoal;
  handleSubmit: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isValid: boolean;
  isDirty: boolean;
  isLoadingGoals: boolean;
  discardAction: () => void;
  hideButtons?: boolean;
  relationship: IRelationship;
}

export const GoalsEditForm: React.FC<IGoalsEditFormProps> = ({
  goal,
  handleSubmit,
  control,
  errors,
  isValid,
  isDirty,
  isLoadingGoals,
  discardAction,
  hideButtons,
  relationship,
}) => {
  // base language
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });
  const isMobile = useMobileMediaQuery();
  const { getProgram } = useSanityOrganizationPrograms({});

  const program = getProgram(relationship.programSlug);
  const customGoalCategories =
    program?.registration?.goals?.custom_goal_categories;

  const selectedGoalSource =
    customGoalCategories && customGoalCategories.length > 0
      ? customGoalCategories
      : organization?.goal_categories?.categories ?? [];

  const goalCategories = selectedGoalSource.map((goalCategory) => ({
    label: goalCategory.goal_category_name,
    value: goalCategory.goal_category_slug?.current,
  }));
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6">
        {`1. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.summary?.category_select_label}`}
        <Asterisk />
      </Typography>
      <FormDropdownTyped
        name="categorySlug"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.summary?.category_select_label
        }
        errors={errors}
        options={goalCategories}
        required
        defaultValue={goal?.categorySlug}
        data-cy="forms_GoalsEditForm_category"
      />
      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`2. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.summary?.objective_input_label}`}
      </Typography>
      <FormTextFieldTyped
        data-cy="forms_GoalsEditForm_objective"
        name="objective"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.summary?.objective_input_label
        }
        errors={errors}
        defaultValue={goal?.objective ?? ''}
      />

      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`3. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.outcomes?.title}`}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" fontWeight={400}>
        {
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.outcomes?.description
        }
      </Typography>
      <FormTextFieldTyped
        data-cy="forms_GoalsEditForm_outcomes"
        name="outcomes"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.outcomes?.title
        }
        errors={errors}
        rows={2}
        defaultValue={goal.outcomes ?? ''}
      />
      <Typography variant="h6" sx={{ mt: 4 }} component={'p'}>
        {`4. ${baseLanguage?.relationships?.goals?.create_and_update_goal?.goal_fields?.benefits?.title}`}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" fontWeight={400}>
        {
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.benefits?.description
        }
      </Typography>
      <FormTextFieldTyped
        data-cy="forms_GoalsEditForm_rationale"
        name="rationale"
        control={control}
        label={
          baseLanguage?.relationships?.goals?.create_and_update_goal
            ?.goal_fields?.benefits?.title
        }
        errors={errors}
        rows={2}
        defaultValue={goal.rationale ?? ''}
      />
      {!hideButtons && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: isMobile ? 'column-reverse' : 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <Button
            data-cy="forms-GoalsEditForm_discard-button"
            variant="outlined"
            color="info"
            size="large"
            fullWidth={isMobile}
            onClick={discardAction}
          >
            {baseLanguage?.globals?.common?.discard_changes_button_label}
          </Button>
          <LoadingButton
            data-cy="forms-GoalsEditForm_submit-button"
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid || !isDirty}
            sx={{
              color: isValid ? 'white' : 'inherit',
              mb: isMobile ? 2 : 0,
            }}
            fullWidth={isMobile}
            loading={isLoadingGoals}
          >
            {baseLanguage.globals?.goals?.edit_goal_button_label}
          </LoadingButton>
        </Box>
      )}
    </form>
  );
};

export default GoalsEditForm;

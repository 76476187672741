import React, { useCallback, useState } from 'react';

import { Box, Alert, CircularProgress } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { IError } from '@guider-global/shared-types';

import { useUsers, useProfiles, useMixpanelEvents } from 'hooks';
import { AvatarUser, FileUpload } from 'components';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

import { Button } from '@guider-global/ui';

export interface IProfilePictureFormProps {
  getProfilesSilently?: boolean;
  getUsersSilently?: boolean;
  getSanityBaseLanguageSilently?: boolean;
  defaultFile?: File | Blob;
  loading?: boolean;
}

export const ProfilePictureForm: React.FunctionComponent<
  IProfilePictureFormProps
> = ({
  getProfilesSilently = false,
  getUsersSilently = false,
  getSanityBaseLanguageSilently = false,
  defaultFile,
  loading,
}) => {
  const { trackMixpanelEvent, setUserProperties } = useMixpanelEvents();
  const { isLoadingSanityBaseLanguage, getBaseLanguage } =
    useSanityBaseLanguage({
      getSilently: getSanityBaseLanguageSilently,
    });

  const pictureFields = getBaseLanguage().profile_fields?.picture;
  const changePictureText = pictureFields?.change_picture;
  const choosePictureText = pictureFields?.choose_picture;
  const removePictureText = pictureFields?.remove_picture;

  // Profiles hook
  const { getProfiles, hasResultsProfiles, reqProfiles, getErrorsProfiles } =
    useProfiles({ getSilently: getProfilesSilently });

  // Users hook
  const { hasResultsUsers } = useUsers({
    getSilently: getUsersSilently,
  });

  // Rest Redux hooks found data
  const profilesFound: boolean = hasResultsProfiles();
  const usersFound: boolean = hasResultsUsers();

  // Profile
  const [profile] = getProfiles();
  const profileId = profile?.id ?? '';
  const profilePictureFound = !!profile?.picture;

  // TODO Get organization from TBC /organization API hook
  const containerName = 'pictures';
  const sasTokenUriPath = '/admin/storage';

  // Local state
  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Errors
  const [uploadErrors, setUploadErrors] = useState<IError[]>([]);
  const profileErrors: IError[] = getErrorsProfiles();
  const hasErrors = uploadErrors.length > 0 || profileErrors.length > 0;

  // Ready state
  const isReady =
    profilesFound &&
    usersFound &&
    !isLoading &&
    !isLoadingSanityBaseLanguage() &&
    !loading;

  // Errors Alert component

  const handleBeforeUpload = useCallback(async (): Promise<void> => {
    setUploadErrors([]);
    setImageUploaded(false);
    setIsLoading(true);
  }, []);

  const handleUploadSuccess = useCallback(
    async (fileUri: string): Promise<void> => {
      try {
        if (profile) {
          await reqProfiles({
            method: 'PATCH',
            url: `/profiles/${profileId}`,
            data: {
              picture: fileUri,
            },
          });
          setImageUploaded(true);
          trackMixpanelEvent(
            'Registration - Add photo screen - Photo changed',
            { 'Photo changed': true },
          );
          setUserProperties({ 'Photo changed': true });
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.log(error.message);
          const { message } = error;
          setUploadErrors([...uploadErrors, { message }]);
          setImageUploaded(false);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      profile,
      profileId,
      reqProfiles,
      uploadErrors,
      trackMixpanelEvent,
      setUserProperties,
    ],
  );

  const handleUploadError = useCallback(
    async (errors: IError[]): Promise<void> => {
      setUploadErrors(errors);
      setIsLoading(false);
      setImageUploaded(false);
    },
    [],
  );

  const handleDeletePicture = useCallback(async () => {
    try {
      if (profile) {
        setIsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, userId, picture, ...data } = profile;
        await reqProfiles({
          method: 'DELETE',
          url: `/profiles/${profileId}/picture`,
          data: {
            ...data,
          },
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.log(error.message);
        const { message } = error;
        setUploadErrors([...uploadErrors, { message }]);
      }
    } finally {
      setImageUploaded(false);
      setIsLoading(false);
    }
  }, [profile, profileId, reqProfiles, uploadErrors]);

  return (
    <>
      <ErrorsAlert
        hasErrors={hasErrors}
        errors={[...uploadErrors, ...profileErrors]}
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          pt: 4,
          flexFlow: {
            xs: 'column nowrap',
            md: 'row nowrap',
            gap: theme.spacing(3),
          },
        })}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            {isLoading || loading ? (
              <Box
                sx={{
                  width: 120,
                  height: 120,
                  display: 'flex',
                  flexFlow: 'column nowrap',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'grey.100',
                  borderRadius: '4px',
                  boxShadow: '0px 0px 0px 1px #E0E0E0',
                }}
              >
                <CircularProgress color="secondary" size={56} />
              </Box>
            ) : (
              <AvatarUser
                sx={{
                  width: 120,
                  height: 120,
                }}
              />
            )}
          </Box>
        </Box>
        <Box>
          <Box width="100%" height="100%" display="flex">
            <Box m="auto">
              <FileUpload
                boxSx={{
                  marginBottom: 2,
                  justifyContent: 'flex-start',
                }}
                uploadApiPath={`/profiles/${profileId}/picture`}
                sasTokenApiPath={sasTokenUriPath}
                containerName={containerName}
                showErrors={false}
                showImagePreview={false}
                buttonIcon={<FileUploadIcon />}
                buttonDisabled={!isReady}
                onBeforeUpload={handleBeforeUpload}
                onUploadSuccess={handleUploadSuccess}
                onUploadError={handleUploadError}
                buttonUploadText={choosePictureText as string}
                buttonUploadedText={changePictureText as string}
                color="info"
                defaultFile={defaultFile}
              />
              {imageUploaded || profilePictureFound ? (
                <Button
                  data-cy="forms-ProfilePictureForm_delete-picture-button"
                  variant="outlined"
                  disabled={!isReady}
                  sx={{ px: 3, height: 'fit-content' }}
                  onClick={handleDeletePicture}
                  color="error"
                >
                  {removePictureText}
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const ErrorsAlert = ({
  hasErrors,
  errors,
}: {
  hasErrors: boolean;
  errors: IError[];
}) =>
  hasErrors ? (
    <Box mt={2}>
      <Alert severity="error">
        {errors.map((error) => JSON.stringify(error.message))}
      </Alert>
    </Box>
  ) : null;

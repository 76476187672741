import { Box, Typography, useTheme } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

export function NoteStatusUnsaved() {
  const theme = useTheme();
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        mt: 2,
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-end',
        },
      }}
      data-cy="relationship-notes-modal-note-form-status-unsaved"
    >
      <Typography
        color="text.secondary"
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 400,
        }}
      >
        <Edit
          fontSize="small"
          sx={{ mr: 1, color: theme.palette.text.disabled }}
        />
        {
          baseLanguage.relationships?.notes?.notes_modal
            ?.notes_modal_create_edit_note?.note_status
            ?.note_status_unsaved_label
        }
      </Typography>
    </Box>
  );
}
